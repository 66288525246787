import './bootstrap';
import '../css/app.css';
import '../css/custom.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '@popperjs/core';

import * as Sentry from "@sentry/vue";
import {createApp, DefineComponent, h} from 'vue';
import {createInertiaApp, router, usePage} from '@inertiajs/vue3';
import {createBootstrap} from 'bootstrap-vue-next'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from 'ziggy-js';
import {createGtm, useGtm} from "@gtm-support/vue-gtm";
import {showToast} from "@/Composables/showToast.js";

const appName = import.meta.env.VITE_APP_NAME || 'Streaflex';
const gtmId = import.meta.env.VITE_GTM_ID || 'GTM-KJ8W4T2W';
const sentryDsn = import.meta.env.VITE_SENTRY_VUE_DSN || null;

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(createBootstrap())
            .use(ZiggyVue)

        if (import.meta.env.PROD) {
            if (sentryDsn) {
                Sentry.init({
                    app,
                    dsn: sentryDsn,
                    tracesSampleRate: 1.0,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                });
            }

            app.use(createGtm({id: gtmId}));

            router.on('navigate', (event) => {
                const gtm = useGtm();
                if (gtm) {
                    gtm.trackView(event.detail.page.component, event.detail.page.url);
                }
            });
        }

        router.on('finish', () => {
            const page = usePage();

            if (page.props.flash) {
                showToast(page.props.flash);
                page.props.flash = null;
            }

            if (page.props.errors) {
                Object.keys(page.props.errors).forEach(key => {
                    showToast(page.props.errors[key], 'danger');
                });
                page.props.errors = {};
            }
        });

        app.mount(el);
    },
    progress: {
        color: '#8b3dff',
        showSpinner: true,
    },
});
