<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'

const {props} = usePage()

const {
    available_locales: locales,
    urls,
    current: currentLocale
} = props.app.locale

// Get browser's locale; fall back to 'en' if not available
const browserLocale = typeof navigator !== 'undefined' && navigator.language
    ? navigator.language.split('-')[0].toLowerCase()
    : 'en'

// Keep track of the selected locale
const selectedLocale = ref<string>(browserLocale)

// Determine if the alert should be displayed
const shouldShowAlert = ref<boolean>(false)

// Define our translation keys and messages
// Long strings are split and concatenated for readability
const translations: Record<string, Record<string, string>> = {
    en: {
        change: 'Change',
        close: 'Close',
        change_language: 'Change language?',
        incorrect_language_detected:
            'We have detected that your browser is set to a different language.' +
            ' You can change the site language to your preferred one',
    },
    ru: {
        change: 'Изменить',
        close: 'Закрыть',
        change_language: 'Изменить язык?',
        incorrect_language_detected:
            'Мы заметили, что ваш браузер настроен на другой язык.' +
            ' Вы можете изменить язык сайта на предпочитаемый',
    },
    fr: {
        change: 'Changer',
        close: 'Fermer',
        change_language: 'Changer la langue?',
        incorrect_language_detected:
            'Nous avons détecté que votre navigateur est configuré dans une autre langue.' +
            ' Vous pouvez changer la langue du site pour celle de votre choix',
    },
    de: {
        change: 'Ändern',
        close: 'Schließen',
        change_language: 'Sprache ändern?',
        incorrect_language_detected:
            'Wir haben festgestellt, dass Ihr Browser auf eine andere Sprache eingestellt ist.' +
            ' Sie können die Sprache der Website auf Ihre bevorzugte ändern',
    },
    ja: {
        change: '変更する',
        close: '閉じる',
        change_language: '言語を変更しますか？',
        incorrect_language_detected:
            'お使いのブラウザが別の言語に設定されていることを検出しました。' +
            ' お好みの言語にサイトの言語を変更できます',
    }
}

// Translation helper function
// Looks up the correct language's translation; falls back to English if not found
const translate = (key: string): string => {
    return translations[browserLocale]?.[key] || translations.en[key] || key
}

// Function to change the current locale by redirecting to the locale-specific URL
const changeLocale = (): void => {
    const url = urls[selectedLocale.value]
    if (url) {
        window.location.href = url
    }
}

// Function to close the alert and store this preference in sessionStorage
const closeAlert = (): void => {
    shouldShowAlert.value = false
    sessionStorage.setItem('language-alert-closed', 'true')
}

// On component mount, check if we should show the language alert
onMounted(() => {
    if (
        !sessionStorage.getItem('language-alert-closed') &&
        locales[browserLocale] &&
        browserLocale !== currentLocale
    ) {
        shouldShowAlert.value = true
    }
})
</script>

<template>
    <div
        v-if="shouldShowAlert"
        class="position-fixed bottom-0 end-0 px-3"
        style="z-index: 1020; max-width: 500px;"
    >
        <div class="alert bg-white shadow w-100" role="alert">
            <div class="row align-items-center">
                <div class="col-12 mb-2">
                    <h5 class="text-dark">
                        {{ translate('change_language') }}
                        <i class="bi bi-translate text-primary ms-1"></i>
                    </h5>
                    <p class="mb-0">
                        {{ translate('incorrect_language_detected') }}
                    </p>
                </div>
                <div class="col-12">
                    <div class="d-flex flex-column h-100 justify-content-between">
                        <select v-model="selectedLocale" class="form-select mb-2 w-100">
                            <option
                                v-for="(name, locale) in locales"
                                :key="locale"
                                :value="locale"
                            >
                                {{ name }}
                            </option>
                        </select>
                        <div class="d-flex gap-2 w-100">
                            <button
                                class="btn btn-primary w-100"
                                @click="changeLocale"
                            >
                                {{ translate('change') }}
                            </button>
                            <button
                                class="btn btn-secondary w-100"
                                @click="closeAlert"
                            >
                                {{ translate('close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
