<script setup lang="ts">
import {ref, computed, watch, onMounted, onBeforeUnmount} from 'vue';
import {trans} from "@/Composables/trans";

enum Theme {
    Light = 'light',
    Dark = 'dark',
    Auto = 'auto',
}

interface ThemeOption {
    value: Theme;
    icon: string;
    label: string;
}

const themeOptions: ThemeOption[] = [
    {value: Theme.Light, icon: 'bi-sun-fill', label: 'theme.light'},
    {value: Theme.Dark, icon: 'bi-moon-stars-fill', label: 'theme.dark'},
    {value: Theme.Auto, icon: 'bi-circle-half', label: 'theme.auto'},
];

const theme = ref<Theme>(Theme.Auto);
const isDarkModePreferred = ref<boolean>(false);
const mediaQuery = ref<MediaQueryList | null>(null);

const appliedTheme = computed<Theme>(() => {
    return theme.value === Theme.Auto
        ? (isDarkModePreferred.value ? Theme.Dark : Theme.Light)
        : theme.value;
});

const currentIconClass = computed<string>(() => {
    const option = themeOptions.find(opt => opt.value === appliedTheme.value);
    return option ? option.icon : '';
});

const handleMediaQueryChange = (event: MediaQueryListEvent): void => {
    isDarkModePreferred.value = event.matches;
};

const setTheme = (newTheme: Theme): void => {
    theme.value = newTheme;
    if (typeof window !== 'undefined') {
        localStorage.setItem('theme', newTheme);
    }
};

onMounted(() => {
    if (typeof window !== 'undefined') {
        const storedTheme = localStorage.getItem('theme') as Theme | null;
        if (storedTheme) {
            theme.value = storedTheme as Theme;
        }

        mediaQuery.value = window.matchMedia('(prefers-color-scheme: dark)');
        isDarkModePreferred.value = mediaQuery.value.matches;
        mediaQuery.value.addEventListener('change', handleMediaQueryChange);

        watch(
            appliedTheme,
            (newTheme: Theme) => {
                document.documentElement.setAttribute('data-bs-theme', newTheme);
            },
            {immediate: true}
        );
    }
});

onBeforeUnmount(() => {
    if (mediaQuery.value) {
        mediaQuery.value.removeEventListener('change', handleMediaQueryChange);
    }
});
</script>

<template>
    <b-dropdown variant="primary" toggle-class="btn-icon" no-caret>
        <template #button-content>
            <i :class="['bi', currentIconClass]"></i>
        </template>
        <b-dropdown-item
            v-for="option in themeOptions"
            :key="option.value"
        >
            <b-button
                variant="outline-primary"
                size="sm"
                pill
                class="w-100"
                :active="theme === option.value"
                @click="setTheme(option.value)"
            >
                {{ trans(option.label) }}
            </b-button>
        </b-dropdown-item>
    </b-dropdown>
</template>
