import {usePage} from '@inertiajs/vue3';

/**
 * Retrieve a translation for the given key and replace placeholders with provided variables
 *
 * @param {string} key - The translation key in dot notation
 * @param {Object} [variables={}] - An object containing the variables to replace in the translation
 * @returns {string} The translated string or the key itself if not found or not a string
 */
export function trans(key: string, variables: Record<string, any> = {}): string {
    const {props} = usePage();
    if (!props.translations) {
        return key;
    }

    const keys = key.split('.');
    let value: any = props.translations;

    for (const k of keys) {
        if (value[k] === undefined) {
            return key;
        }
        value = value[k];
    }

    if (typeof value === 'string') {
        return value.replace(/{{\s*(\w+)\s*}}|:(\w+)/g, (match, variable1, variable2) => {
            const variable = variable1 || variable2;
            if (variables.hasOwnProperty(variable)) {
                return variables[variable];
            }
            return match;
        });
    }

    return key;
}
