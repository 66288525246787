<script setup>
import LangPicker from '@/Components/LangPicker.vue';
import ThemePicker from '@/Components/ThemePicker.vue';
import ScrollTopButton from '@/Components/ScrollTopButton.vue';
import LangAlert from "@/Components/LangAlert.vue";
import ClientOnly from "@/Components/Helpers/ClientOnly.vue";
import Logo from "@/Components/Logo.vue";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {ref, onMounted, computed} from 'vue';
import {Link, Head, usePage} from '@inertiajs/vue3';
import {route} from '@/Composables/route'
import {trans} from "@/Composables/trans";

const page = usePage();

const props = defineProps({
    title: {
        required: true
    },
    description: {
        required: true
    },
});

const navCanvas = ref(null);
const navCanvasBtn = ref(null);

let bsOffCanvas;

onMounted(async () => {
    AOS.init({once: true, offset: 0,});

    const {Offcanvas} = await import('bootstrap');

    if (navCanvas.value) {
        bsOffCanvas = new Offcanvas(navCanvas.value, {scroll: true});
    }

    if (navCanvasBtn.value) {
        navCanvasBtn.value.addEventListener('click', function () {
            if (bsOffCanvas) {
                bsOffCanvas.toggle();
            }
        });
    }
});

const progressBarWidth = computed(() => {
    const totalViewers = page.props.auth.user.plan_committed_viewers || 0;
    const viewersInUse = page.props.auth.user.plan_viewers_in_use || 0;

    return Math.min(totalViewers > 0 ? (viewersInUse / totalViewers) * 100 : 0, 100);
});

const hrefLangDict = computed(() => {
    const urls = page.props.app.locale.urls || {};
    const defaultLocale = page.props.app.locale.default_locale || 'en';
    const filterLocales = page.props.filter_locales || null;

    let hrefs = Object.keys(urls)
        .filter(locale => {
            return !filterLocales || filterLocales.includes(locale);
        })
        .map(locale => ({
            locale,
            url: urls[locale] || page.props.app.url,
        }));

    const defaultUrl = urls[defaultLocale] || page.props.app.url;

    hrefs.push({
        locale: 'x-default',
        url: defaultUrl,
    });

    return hrefs;
});

</script>

<template>
    <Head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>{{ title }}</title>
        <meta name="description" :content="description"/>
        <meta name="robots" content="index, follow"/>
        <meta name="format-detection" content="telephone=no">
        <meta name="apple-mobile-web-app-capable" content="yes">
        <meta name="apple-mobile-web-app-status-bar-style" content="default">
        <meta name="mobile-web-app-capable" content="yes">
        <link rel="icon" type="image/png" href="/assets/favicon.png"/>
        <link rel="canonical" :href="page.props.app.ziggy.location">
        <template v-for="hrefLang in hrefLangDict" :key="hrefLang.locale">
            <link rel="alternate" :href="hrefLang.url" :hreflang="hrefLang.locale" />
        </template>
        <component is="script" type="application/ld+json" v-if="page.props.app.schema_org">
            {{ page.props.app.schema_org }}
        </component>
    </Head>
    <div class="d-flex flex-column min-vh-100">
        <nav class="navbar navbar-expand-lg navbar-light w-100">
            <div class="container px-3">
                <Link :href="route('index')" class="navbar-brand">
                    <Logo/>
                </Link>
                <button
                    class="navbar-toggler offcanvas-nav-btn text-bg-primary border-primary"
                    type="button"
                    ref="navCanvasBtn"
                >
                    <i class="bi bi-list"/>
                </button>
                <div class="offcanvas offcanvas-start offcanvas-nav" ref="navCanvas">
                    <div class="offcanvas-header">
                        <Link :href="route('index')" class="text-inverse">
                            <Logo/>
                        </Link>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas"/>
                    </div>
                    <div class="offcanvas-body pt-0 align-items-center">
                        <ul class="navbar-nav align-items-lg-center">
                            <li class="nav-item">
                                <Link class="nav-link" :href="route('about')">
                                    <i class="bi bi-info-circle-fill d-none d-lg-inline-block text-primary me-1"/>
                                    {{ trans('layout.about') }}
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" :href="route('plans')">
                                    <i class="bi bi-box2-fill d-none d-lg-inline-block text-danger me-1"/>
                                    {{ trans('layout.plans') }}
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" :href="route('reviews')">
                                    <i class="bi bi-star-fill d-none d-lg-inline-block text-primary me-1"/>
                                    {{ trans('layout.reviews') }}
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" :href="route('support')">
                                    <i class="bi bi-chat-dots-fill d-none d-lg-inline-block text-primary me-1"/>
                                    {{ trans('layout.support') }}
                                </Link>
                            </li>
                            <li class="nav-item dropdown">
                                <div class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                                    <i class="bi bi-bookmarks-fill d-none d-lg-inline-block text-primary me-1"/>
                                    {{ trans('layout.more') }}
                                </div>
                                <div class="dropdown-menu dropdown-menu-md">
                                    <Link class="dropdown-item mb-3 text-body" :href="route('blog.index')">
                                        <div class="d-flex align-items-center">
                                            <i class="bi bi-lightbulb-fill fs-4 text-primary"/>
                                            <div class="ms-3 lh-1">
                                                <h5 class="mb-1">
                                                    {{ trans('layout.blog') }}
                                                </h5>
                                                <p class="mb-0 fs-6">
                                                    {{ trans('layout.news_articles_and_life_hacks') }}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link class="dropdown-item text-body" :href="route('documents')">
                                        <div class="d-flex align-items-center">
                                            <i class="bi bi-mortarboard-fill fs-4 text-primary"/>
                                            <div class="ms-3 lh-1">
                                                <h5 class="mb-1">
                                                    {{ trans('layout.legal_documents') }}
                                                    <span class="text-primary ms-1"/>
                                                </h5>
                                                <p class="mb-0 fs-6">
                                                    {{ trans('layout.service_rules') }}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        <div class="mt-3 mt-lg-0 d-flex align-items-center ms-auto">
                            <template v-if="page.props?.auth?.user">
                                <div
                                    class="d-none d-xxl-flex justify-content-center align-items-center me-4"
                                    v-if="page.props.auth.plan"
                                >
                                    <div class="fw-bold text-primary me-3">
                                    <span>
                                        <span>{{ page.props.auth.user.plan_viewers_in_use }}</span>
                                        <span>/</span>
                                        <span>{{ page.props.auth.user.plan_committed_viewers }}</span>
                                        <span class="ms-1"><i class="bi bi-person-fill"/></span>
                                    </span>
                                    </div>
                                    <div class="progress rounded-pill" style="height: 0.7rem; width: 100px">
                                        <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"/>
                                    </div>
                                </div>
                                <Link :href="route('index')" class="btn btn-primary d-flex align-items-center me-2">
                                    {{ trans('layout.dashboard') }}
                                </Link>
                                <Link :href="route('logout')" class="btn btn-light">
                                    {{ trans('layout.logout') }}
                                </Link>
                            </template>
                            <template v-else>
                                <Link :href="route('register')" class="btn btn-primary d-flex align-items-center me-2">
                                    {{ trans('layout.create_account') }}
                                </Link>
                                <Link :href="route('login')" class="btn btn-light d-flex align-items-center">
                                    {{ trans('layout.login') }}
                                </Link>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <main class="flex-grow-1" data-aos="fade-in">
            <div class="pattern-square"/>
            <slot/>
        </main>
        <footer class="shadow pb-5 text-center position-relative" style="border-top: 1px solid var(--bs-gray-100);">
            <div class="pt-5 px-3 px-sm-6 px-md-10 mb-4">
                <p class="mb-3">
                    Copyright <i class="bi bi-c-circle-fill text-primary mx-1"/> 2024 — {{ page.props.app.name }}
                </p>
                <p class="mb-3">
                    {{ trans('layout.provide_cookies') }} / <br>
                    {{ trans('layout.not_affiliated', {app_name: page.props.app.name}) }}
                </p>
            </div>
            <div class="d-flex gap-3 align-items-center justify-content-center mb-3 mb-sm-0">
                <LangPicker/>
                <ThemePicker/>
                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <a
                            class="btn btn-ghost-secondary btn-sm btn-icon rounded-circle card-lift"
                            :href="trans('layout.telegram_link')"
                            target="_blank"
                        >
                            <i class="bi bi-telegram fs-3"/>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a
                            class="btn btn-ghost-secondary btn-sm btn-icon rounded-circle card-lift"
                            href="https://discord.gg/BTJkC9Hbtp"
                            target="_blank"
                        >
                            <i class="bi bi-discord fs-3"/>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a
                            class="btn btn-ghost-secondary btn-sm btn-icon rounded-circle card-lift"
                            href="https://x.com/streaflex"
                            target="_blank"
                        >
                            <i class="bi bi-twitter-x fs-3"/>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    </div>
    <ScrollTopButton/>
    <ClientOnly><LangAlert/></ClientOnly>
    <b-toast-orchestrator/>
</template>
