<script setup lang="ts">
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';

const {props} = usePage();

const filterLocales = computed(() => props.filter_locales || null);

const locales = computed(() => props.app.locale.available_locales);
const urls = computed(() => props.app.locale.urls);

const currentLocale = computed(() => props.app.locale.current);
const currentLocaleName = computed(() => locales.value[currentLocale.value]);

const changeLocale = (locale: string): void => {
    const url = urls.value[locale];
    if (url) {
        window.location.href = url;
    }
};

const isLocaleEnabled = (locale: string): boolean => {
    return !filterLocales.value || filterLocales.value.includes(locale);
};
</script>

<template>
    <b-dropdown variant="primary" class="d-inline-block">
        <template #button-content>
            {{ currentLocaleName }}
        </template>
        <b-dropdown-item
            v-for="(name, locale) in locales"
            :key="locale"
        >
            <b-button
                variant="outline-primary"
                size="sm"
                pill
                :class="['w-100', {active: currentLocale === locale}]"
                :disabled="!isLocaleEnabled(locale)"
                @click.prevent="isLocaleEnabled(locale) && changeLocale(locale)"
            >
                {{ name }}
            </b-button>
        </b-dropdown-item>
    </b-dropdown>
</template>
