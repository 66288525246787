<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import {Link, useForm, usePage} from '@inertiajs/vue3';
import {route} from '@/Composables/route'
import {trans} from "@/Composables/trans";

const page = usePage();

const onboardingForm = useForm({
    channel_link: ''
});

const submitOnboardingForm = () => {
    onboardingForm.post(route('channels.onboarding'));
};
</script>

<template>
    <app-layout :title="trans('meta.index.title')" :description="trans('meta.index.description')">
        <b-container class="py-lg-8 py-5">
            <b-row class="justify-content-center">
                <b-col xl="8" lg="10" cols="12">
                    <div class="text-center d-flex flex-column gap-5">
                        <div class="d-flex justify-content-center">
                            <Link
                                :href="route('plans')"
                                class="text-bg-primary p-2 fs-6 rounded-pill lh-1 d-flex align-items-center"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <span class="badge text-bg-dark">
                                  {{ trans('temporarily.wow') }}
                                </span>
                                <span class="mx-2">
                                  {{ trans('temporarily.sale') }}
                                </span>
                            </Link>
                        </div>
                        <div class="d-flex flex-column gap-3 mx-lg-8">
                            <h1 class="mb-0 display-4">
                                {{ trans('index.index.enhance_popularity') }}
                            </h1>
                            <p class="mb-0 lead">
                                {{ trans('index.index.grow_your_stream', {app_name: page.props.app.name}) }}
                                <span class="badge rounded-pill text-bg-dark">
                                    {{ trans('index.index.free_viewers') }}
                                </span>
                                {{ trans('index.index.for_a_month') }}
                                <b-img
                                    src="/assets/images/animated/gift.gif"
                                    class="ms-1"
                                    width="20px"
                                    alt="Gift Emoji"
                                    lazy
                                />
                            </p>
                        </div>
                        <b-form class="row g-2 d-flex mx-lg-7" @submit.prevent="submitOnboardingForm">
                            <b-col lg="8" xxl="9" cols="12">
                                <b-form-input
                                    v-model="onboardingForm.channel_link"
                                    type="text"
                                    class="form-control-lg"
                                    placeholder="twitch.tv/channel"
                                    required
                                />
                            </b-col>
                            <b-col lg="4" xxl="3" cols="12">
                                <div class="d-grid">
                                    <b-button
                                        type="submit"
                                        variant="primary"
                                        size="lg"
                                        :disabled="onboardingForm.processing"
                                    >
                                        {{ trans('index.index.boost') }}
                                        <b-img
                                            src="/assets/images/animated/thunder.gif"
                                            class="ms-1"
                                            width="20px"
                                            alt="Thunder Emoji"
                                            lazy
                                        />
                                    </b-button>
                                </div>
                            </b-col>
                        </b-form>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <section class="py-5">
            <b-container>
                <b-row class="border-top border-bottom">
                    <b-col lg="4" class="border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-trophy-fill display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.quality') }}</h4>
                                <p class="mb-0">{{ trans('index.index.quality_description') }}</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4" class="border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-heart-fill display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.support') }}</h4>
                                <p class="mb-0">{{ trans('index.index.support_description') }}</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-person-fill-check display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.stability') }}</h4>
                                <p class="mb-0">{{ trans('index.index.stability_description') }}</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="border-bottom">
                    <b-col lg="4" class="border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-shield-fill-check display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.security') }}</h4>
                                <p class="mb-0">{{ trans('index.index.security_description') }}</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4" class="border-end-lg border-bottom border-bottom-lg-0">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-percent display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.discounts') }}</h4>
                                <p class="mb-0">{{ trans('index.index.discounts_description') }}</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="text-center py-lg-5 p-4">
                            <div class="mb-4">
                                <i class="bi bi-stack display-6 text-primary"></i>
                            </div>
                            <div>
                                <h4>{{ trans('index.index.stream_analysis') }}</h4>
                                <p class="mb-0">{{ trans('index.index.stream_analysis_description') }}</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="py-5">
            <b-container>
                <b-row>
                    <b-col lg="10" class="offset-lg-1" md="12" cols="12">
                        <div class="text-center mb-7">
                            <h2>{{ trans('index.index.faq_title') }}</h2>
                            <p class="mb-0">
                                {{ trans('index.index.faq_intro') }}
                                <br/>
                                <Link :href="route('support')" class="text-primary">
                                    {{ trans('index.index.ask_support') }}
                                </Link>
                            </p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="10" class="offset-lg-1" md="12" cols="12">
                        <b-accordion flush>
                            <b-accordion-item
                                :title="trans('index.index.faq.trial_service')"
                                button-class="h5 text-reset mb-0 p-2 bg-transparent shadow-none"
                                class="border mb-2 rounded-3 p-3"
                                body-class="p-0"
                            >
                                <div class="mt-3">
                                    {{ trans('index.index.faq.trial_answer') }}
                                </div>
                            </b-accordion-item>
                            <b-accordion-item
                                :title="trans('index.index.faq.bots_question')"
                                button-class="h5 text-reset mb-0 p-2 bg-transparent shadow-none"
                                class="border mb-2 rounded-3 p-3"
                                body-class="p-0"
                            >
                                <div class="mt-3">
                                    {{ trans('index.index.faq.bots_answer') }}
                                </div>
                            </b-accordion-item>
                            <b-accordion-item
                                :title="trans('index.index.faq.change_plan_question')"
                                button-class="h5 text-reset mb-0 p-2 bg-transparent shadow-none"
                                class="border mb-2 rounded-3 p-3"
                                body-class="p-0"
                            >
                                <div class="mt-3">
                                    {{ trans('index.index.faq.change_plan_answer') }}
                                </div>
                            </b-accordion-item>
                            <b-accordion-item
                                :title="trans('index.index.faq.plan_choice_question')"
                                button-class="h5 text-reset mb-0 p-2 bg-transparent shadow-none"
                                class="border mb-2 rounded-3 p-3"
                                body-class="p-0"
                            >
                                <div class="mt-3">
                                    {{ trans('index.index.faq.plan_choice_answer') }}
                                </div>
                            </b-accordion-item>
                            <b-accordion-item
                                :title="trans('index.index.faq.compensation_question')"
                                button-class="h5 text-reset mb-0 p-2 bg-transparent shadow-none"
                                class="border mb-2 rounded-3 p-3"
                                body-class="p-0"
                            >
                                <div class="mt-3">
                                    {{ trans('index.index.faq.compensation_answer') }}
                                </div>
                            </b-accordion-item>
                        </b-accordion>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </app-layout>
</template>
